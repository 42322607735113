<template>
  <v-app data-testid="bare-theme" class="pos-relative overflow-auto">
    <v-main class="pt-0">
      <router-view :active-layout-component="activeLayoutComponent" />
      <app-resources />
    </v-main>
  </v-app>
</template>

<script setup>
import AppResources from '#src/AppResources.vue';
import WebappLayout from '#src/layouts/WebappLayout.vue';

import { markRaw } from 'vue';

const activeLayoutComponent = markRaw(WebappLayout);
</script>
